.img-acco-do img{
    width: 750px !important;
    height: 250px;
    object-fit: cover;
}
.img-acco img{
    width: 400px !important;
    height: 250px;
    object-fit: cover;
}

.liniarr{
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
}

.img-acco{
 position: relative;   
}
.img-acco-do{
 position: relative;   
}
.img-acco::before{
    position: absolute;
    content: "";
    background-color: rgb(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    right: 0;
    
}
.img-acco-do::before{
    position: absolute;
    content: "";
    background-color: rgb(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    right: 0;
    
}

.mor{
   display: flex;
   justify-content: end;
  
   color: red; 
}
.mor button{
   display: flex;
   justify-content: end;
   padding: 10px;
   border-radius: 10px;
   color: red; 
}
.mor button a{
  text-decoration: none;
   color: red; 
}

.content li{
 color: white;
 font-weight: 700;
}