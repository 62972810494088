.im-the-white{
    background: #ffffff !important;
}

.titleSection h2{
    color: #000000 !important;
}

.birthdaydetail p {
    color: #000000;
}