banner-section {
    position: relative;
}

.banner-section .background-text {
    position: absolute;
    right: -280px;
    bottom: 190px;
    z-index: 99;
    text-transform: uppercase;
    font-size: 240px;
    font-weight: 700;
    letter-spacing: -5px;
    line-height: 200px;
    color: rgb(255 255 255 / 0.1);
    transform: rotate(-30deg);
}

.banner-section .background-text .text-2 {
    position: absolute;
    top: -5px;
    left: 15px;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: rgb(255 255 255 / 0.2);
    color: transparent;
}

.three-item-carousel,
.banner-slider {
    position: relative;
    height: 100% !important;
    z-index: 9;
}

.banner-section .swiper-slide {
    position: relative;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.banner-section .swiper-slide:before {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: #000000;
    opacity: 0.0;
    content: "";
}

.banner-section .content-outer {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: 100%;
    width: 100%;
    display: table;
    vertical-align: middle;
}

.banner-section .content-box {
    position: relative;
    padding: 120px 15px 10px;
    min-height: 900px;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.banner-section .content-box .inner {
    position: relative;
    opacity: 0;
    -webkit-transform: translateX(100px);
    -ms-transform: translateX(100px);
    transform: translateX(100px);
    top: -90px;
}

.banner-section .swiper-slide-active .content-box .inner {
    opacity: 1;
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);
    transform: translateX(0px);
}

.banner-section .content-box h1 {
    position: relative;
    color: #ffffff;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px);
    margin-bottom: 30px;
}

.banner-section .content-box h1:before {
    position: absolute;
    content: '';
    height: 3px;
    width: 76px;
    bottom: 0;
    left: 0;
}

.banner-section .content-box.justify-content-center h1:before {
    left: 50%;
    transform: translateX(-50%);
}

.banner-section .swiper-slide-active .content-box h1 {
    opacity: 1;
    visibility: visible;
    -webkit-transition-delay: 800ms;
    -o-transition-delay: 800ms;
    transition-delay: 800ms;
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
}

.banner-section .content-box h4 {
    position: relative;
    display: block;
    line-height: 1.2em;
    margin-bottom: 30px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
    color: var(--theme-color);
    font-size: 24px;
    font-weight: 500;
    font-family: "DM Sans";
}

.banner-section .swiper-slide-active h4 {
    opacity: 1;
    visibility: visible;
    -webkit-transition-delay: 1200ms;
    -o-transition-delay: 1200ms;
    transition-delay: 1200ms;
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
}

.banner-section .content-box .text {
    position: relative;
    font-size: 24px;
    font-weight: 300;
    line-height: 34px;
    color: #ffffff;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
    -webkit-transform: translateX(-50px);
    -ms-transform: translateX(-50px);
    transform: translateX(-50px);
    margin-bottom: 40px;
}

.banner-section .swiper-slide-active .text {
    opacity: 1;
    visibility: visible;
    -webkit-transition-delay: 1600ms;
    -o-transition-delay: 1600ms;
    transition-delay: 1600ms;
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);
    transform: translateX(0px);
}

.banner-section .link-box {
    -webkit-transform: scaleY(0);
    -ms-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
    -webkit-transition-delay: 2000ms;
    -o-transition-delay: 2000ms;
    transition-delay: 2000ms;
    -webkit-transform-origin: bottom;
    -ms-transform-origin: bottom;
    transform-origin: bottom;
    margin: 0 -10px;
}

.banner-section .swiper-slide-active .link-box {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.banner-section .link-box a {
    -webkit-transition: .5s ease;
    -o-transition: .5s ease;
    transition: .5s ease;
    margin: 0 10px 10px;
}


.banner-section .video-box {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
    -webkit-transform: scaleY(0);
    -ms-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
    -webkit-transition-delay: 2000ms;
    -o-transition-delay: 2000ms;
    transition-delay: 2000ms;
    -webkit-transform-origin: bottom;
    -ms-transform-origin: bottom;
    transform-origin: bottom;
}

.banner-section .swiper-slide-active .video-box {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}
.banner-section .video-btn {
    position: relative;
}

.banner-section .video-btn span {
    position: relative;
    width: 100px;
    height: 100px;
    text-align: center;
    line-height: 100px;
    border-radius: 50%;
}

.banner-section .video-btn a {
    position: relative;
	height: 67px;
	width: 67px;
	line-height: 70px;
	border-radius: 50%;
	color: #111;
	text-align: center;
	font-size: 20px;
	display: inline-block;
	position: relative;
	background-color: #fff;
	transition: .5s;
	margin-right: 52px;
    transition: .5s;
}

.banner-section .video-btn a:hover {
    color: #fff;
    background-color: var(--theme-color);
}

.banner-section .video-btn a:before {
    position: absolute;
    content: '';
    width: 92px;
    height: 92px;
    border-radius: 46px;
    border: 1px solid rgba(255,255,255,0.35);
    left: -12px;
    top: -12px;
}


.banner-section .video-btn .ripple:after {
	position: absolute;
	left: 0;
	top: 0;
	border-radius: 50%;
	-webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.14);
	-ms-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.14);
	-o-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.14);
	box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.14);
	-webkit-animation: ripple 3s infinite;
	animation: ripple 3s infinite;
}

.banner-section .video-btn .ripple:after {
	-webkit-animation-delay: .6s;
	animation-delay: .6s;
	content: "";
	position: absolute;
	right: 0;
	bottom: 0;
}

@-webkit-keyframes ripple {
	70% {
		-webkit-box-shadow: 0 0 0 70px rgba(244, 68, 56, .0);
		        box-shadow: 0 0 0 70px rgba(244, 68, 56, .0);
	}
	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(244, 68, 56, .0);
		        box-shadow: 0 0 0 0 rgba(244, 68, 56, .0);
	}
}

@keyframes ripple {
	70% {
		-webkit-box-shadow: 0 0 0 70px rgba(244, 68, 56, .0);
		        box-shadow: 0 0 0 70px rgba(244, 68, 56, .0);
	}
	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(244, 68, 56, .0);
		        box-shadow: 0 0 0 0 rgba(244, 68, 56, .0);
	}
}

.banner-section .video-box span {
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 47px;
    color: #ffffff;
    font-weight: 700;
    font-family: "Roboto";
    text-transform: uppercase;
}



/* banner nav */

.banner-section .banner-slider-nav {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.banner-section .banner-slider-button-next {
    position: relative;
    width: 68px;
    height: 68px;
    line-height: 68px;
    text-align: center;
    color: #fff;
    cursor: pointer;
    z-index: 9;
    margin-right: 50px;
    font-size: 43px;
    background-color: transparent;
    transition: .5s;
    border-radius: 50%;
    border: 2px solid #fff;
}

.banner-section .banner-slider-button-next:hover {
    color: #fff;
    background: var(--theme-color);
    border-color: var(--theme-color);
}

.banner-section .banner-slider-button-prev {
    position: relative;
    width: 68px;
    height: 68px;
    line-height: 68px;
    text-align: center;
    color: #fff;
    cursor: pointer;
    z-index: 9;
    margin-left: 50px;
    font-size: 43px;
    background-color: transparent;
    transition: .5s;
    transform: rotate(180deg);
    border-radius: 50%;
    border: 2px solid #fff;
}

.banner-section .banner-slider-button-prev:hover {
    color: #fff;
    background: var(--theme-color);
    border-color: var(--theme-color);
}

/* Style Two */
.banner-section .banner-slider-nav.style-two {
    left: 0;
    right: auto;
    top: auto;
}

.banner-section .banner-slider-nav.style-two .banner-slider-button-prev {
    width: 106px;
    height: 53px;
    border-radius: 0;
    margin: 0;
    line-height: 53px;
    background: #293e9c;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
}

.banner-section .banner-slider-nav.style-two .banner-slider-button-next {
    height: 53px;
    width: 106px;
    border-radius: 0;
    margin: 0;
    line-height: 53px;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
}

.banner-section .banner-slider-nav.style-two .banner-slider-button-next i {
    margin-left: 10px;
    position: relative;
    top: 1px;
}

.banner-section .banner-slider-nav.style-two .banner-slider-button-prev i {
    margin-right: 10px;
    top: 1px;
    position: relative;
}

/* nav style three */

.banner-section .banner-slider-nav.style-three {
    left: auto;
    right: 60px;
    max-width: 68px;
    height: 160px;
    bottom: 45px;
    top: auto;
    flex-direction: column-reverse;
}

.banner-section .banner-slider-button-prev {
    margin: 0;
}

.banner-section .banner-slider-button-next {
    margin: 0;
}

/* nav style four */

.banner-section .banner-slider-nav.style-four {
    left: auto;
    right: 55px;
    max-width: 136px;
    bottom: 315px;
    top: auto;
}

.banner-section .banner-slider-nav.style-four .banner-slider-button-prev {
    border-radius: 0;
    border-left: 1px solid #fff;
}

.banner-section .banner-slider-nav.style-four .banner-slider-button-next {
    border-radius: 0;
    border-left: 1px solid #fff;
}

/* nav style five */

.banner-section .banner-slider-nav.style-five {
    left: auto;
    right: 90px;
    max-width: 68px;
    height: 160px;
    flex-direction: column-reverse;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;
}

.banner-section .swiper-slide-active .content-box .inner {
    opacity: 1;
    transform: translateX(0);
    transition: all .5s ease;
}


@media (max-width: 700px){
    .banner-section .content-box .inner {
        position: relative;
        opacity: 0;
        margin-left: -264px !important;
        transform: translateX(100px);
    }

    .banner-section .content-box h1 {
        position: relative;
        color: #ffffff;
        opacity: 0;
        width: 291px;
        font-size: 36px;
        visibility: hidden;
        transition: all 500ms ease;
        transform: translateY(-20px);
        margin-bottom: 30px;
    }

    .banner-section .content-box .text {
        position: relative;
        font-size: 24px;
        font-weight: 300;
        line-height: 34px;
        width: 341px;
        color: #ffffff;
        opacity: 0;
        visibility: hidden;
        -webkit-transition: all 500ms ease;
        -o-transition: all 500ms ease;
        transition: all 500ms ease;
        -webkit-transform: translateX(-50px);
        -ms-transform: translateX(-50px);
        transform: translateX(-50px);
        margin-bottom: 40px;
    }
    
    

}