

.award{
    display: none;
}

.imagee{
    width: 600px;
    height: 600px;
}
.imagee img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}