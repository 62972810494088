
.footer  {
    background-color:#d3d3d3;
    padding: 10vh 0px 0px 0px !important;
    margin-top: 80px;
}
.footer p  {
   color: black !important;
}



.footer-logo {
    width: 150px;
    height: 80px;
}

.footer-logo img {
    width: 100%;
    height: 100%;
}

.address_main p {
    padding: 48px 0px;
    text-align: left;
    font-size: 14px;
    color: #000000;
    font-family: Open Sans, sans-serif;

}

.footer-icon {
    float: left;
    margin-top: 3px;
    width: 100%;
    margin-bottom: 3px;
}

.footer-icon ul {
    margin: 0px;
    padding: 0px;
    width: 100%;
    float: left;
    font-size: 14px;
    color: #000000;
}

.footer-icon ul li {
    text-align: left;
    margin-bottom: 10px;
    list-style: none;
    color: #000000;
   
    font-family: Open Sans, sans-serif;

}

.footer-icon ul li i {
    margin-right: 12px;
    text-align: center;
    color: black;
}

.footer-icon ul li i svg {
    font-size: 20px;
    color: black;
}

.footer-icon ul li a {
    text-decoration: none;
    color: black;
}

.footer-icon ul li a svg {
    color: black;
}

.footer-head h3 {
    font-size: 25px;
    color: #000000 !important;
    text-decoration: underline var(---TheamColor1);
    margin-bottom: 20px;
    font-family: var(--headingFont) !important;
}

.footer-link ul {
    padding: 0px;
    text-align: left;
}

.footer-service ul {
    padding: 0px;
    text-align: left;
}

.footer-service ul li {
    text-align: left;
    list-style: none;
    line-height: 30px;
}

.footer-service ul li a {
    width: 100%;
    text-decoration: none;
    font-size: 16px;
    color: black;
    font-family: Open Sans, sans-serif;


}

.footer-link ul li {
    text-align: left;
    list-style: none;
    line-height: 30px;
    color: black !important;
}

.footer-link ul li a {
    width: 100%;
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    font-family: Open Sans, sans-serif !important;


    color: #000000;
}

.footer-add ul li {
    text-align: left;
    list-style: none;
    line-height: 25px;
}

.footer-add ul li a {
    text-decoration: none;
    font-size: 16px;
    font-family: Open Sans, sans-serif !important;

    color: #ffffff;
}

.footer-social ul {
    display: flex;
    padding: 0px;
}

.footer-social ul li {
    list-style: none;
    margin: 0px 14px 0px 0px;
}

.footer-social ul li i {
    font-size: 25px;
}

.footer-social ul li i svg {
    color: var(---TheamColor3);
}

.copyright-area {
    padding: 25px 0;
}

.copyright-text p {
    margin: 0;
    font-size: 14px;
    color: black;
}
.copyright-text a {
   
    color:rgb(141 77 2);
}

.footer-add ul {
    padding: 0px;
}

.footer-add p {}


.copyright-text p a {
    font-weight: 100;

    font-family: Open Sans, sans-serif !important;

    text-decoration: none;
}

@media(max-width:992px) {
    .footer-head h3 {
        font-size: 20px;
    }

    .footer-icon ul li {
        padding-left: 10px;
    }

    .footer-service ul li {
        padding-left: 10px;
    }
    .footer-service ul li a {
        color: #ffffff !important;
    }

    .footer-link ul li {
        padding-left: 10px;
    }

    .footer-add ul li {
        padding-left: 10px;
    }

    .footer-logo {
        width: 150px;
        height: auto;
    }
}

.footer-logo img{
  width: 116px !important;
}